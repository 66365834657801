<template>
  <div>
    <div style="font-size: 36px">{{ $t('tools.a6') }}</div>
    <div class="phone-parameters flex">
      <!-- <el-upload
        class="upload-demo"
        action="https://jsonplaceholder.typicode.com/posts/"
        :before-upload="downFile"
        :show-file-list="false"
        :limit="1"> -->
      <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :http-request="upload">
        <el-button type="primary" :disabled="progress>0 && progress<100">{{ $t('tools.a15') }}</el-button>
      </el-upload>
      <el-button type="primary" @click="download" :disabled="progress>0 && progress<100">{{ $t('param.a101') }}
      </el-button>
      <!-- </el-upload> -->
    </div>
    <div class="refresh-btn">
      <el-progress :text-inside="true" :stroke-width="48" :percentage="progress" color="#5D80F5"></el-progress>
      <div
          style="position: absolute;width: 410px;text-indent: 10px;height: 48px;top:0;left: 0;color: white;white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">
        {{ state }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pid: "",
      id: "",

      // fileUrl: '',

      // progress: 0,
      // state: ''
    };
  },
  computed: {
    progress() {
      return this.$root.progress
    },
    fileUrl() {
      return this.$root.fileUrl
    },
    state() {
      return this.$root.state
    }
  },
  created() {
    let data = sessionStorage.getItem("file");
    if (data) {
      this.pid = JSON.parse(data).id;
    }

    this.getFile();
    // this.edit = sessionStorage.getItem('edit');
  },

  methods: {
    getFile() {
      this.postRequest('index/get_software_url', {}, (res) => {
        this.$root.fileUrl = res.data;
      }, true)
    },
    upload(file) {
      // this.progress = 0;
      this.$root.progress = 0
      this.$root.state = '';
      let formdata = new FormData();
      formdata.append('file', file.file)
      formdata.append('uid', sessionStorage.getItem('id'))
      formdata.append('name', file.file.name)
      this.postFile('index/shangchan_software', formdata, (res) => {
        this.$root.fileUrl = res.data;
        this.$root.state = vue1.$t('tools.a15') + '-' + file.file.name
      }, (evt) => {
        // this.progress = Number((evt.loaded/evt.total*100).toFixed(1));
        this.$root.progress = Number((evt.loaded / evt.total * 100).toFixed(1))
      })
    },
    download() {
      // let url = 'http://fatie.tolinksoft.com/uploads/ceshi.txt'
      let url = this.$root.fileUrl;
      if (typeof url== "undefined" || null == url || "" === url){
        return this.$message({
          type: 'warning',
          message: this.$t('message.no_firmware')
        })
      } else {
        if (url.indexOf('doc') > -1) {
          this.downloadByLink(url, '_blank')
        } else {
          let name = url.substr(url.lastIndexOf('/') + 1)
          this.downloadByURL(url, name || '', name ? name.split('.')[1] : '')
        }
      }
    },
    downloadByLink(url, target) {
      const link = document.createElement('a');
      link.href = url;
      link.target = target;
      link.click();
    },
    downloadByURL(url, name, type) {
      this.getRequest(url, {}, (res) => {
        const a = document.createElement('a');
        const href = window.URL.createObjectURL(res);
        a.href = href;
        a.download = name || 'OAT';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(href);
        document.body.removeChild(a);
        // this.state = this.$t('param.a101') + '-' + (name || 'OAT')
        this.$root.state = vue1.$t('param.a101') + '-' + (name || 'OAT')
      }, (evt) => {
        this.$root.progress = Number((evt.loaded / evt.total * 100).toFixed(1))
        // this.progress = Number((evt.loaded / evt.total * 100).toFixed(1));
      })
    },
    // progressVal(val) {
    //   if(val < 100) return val+'%'
    //   // else return '上传成功'
    // },
    downFile(e) {
      if ('download' in document.createElement('a')) {

        const elink = document.createElement('a')

        elink.download = e.name

        elink.style.display = 'none'

        elink.href = URL.createObjectURL(e)

        document.body.appendChild(elink)

        elink.click()

        URL.revokeObjectURL(elink.href)

        document.body.removeChild(elink)

      } else {

        // 兼容IE10+下载

        navigator.msSaveBlob(e, e.name)

      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.phone-parameters .el-button {
  /*width: 180px !important;*/
}
</style>
<style scoped>
.desc-info {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 30px;
}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 6%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.refresh-btn {
  width: 450px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  /* background: #D8D8D8; */
  border-radius: 24px;
  /* border: 1px solid #979797; */
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 250px auto 0;
  cursor: pointer;
  position: relative;
}

.phone-parameters {
  margin: 30px 0;
}
</style>
